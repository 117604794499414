// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-reference-connect-index-js": () => import("./../../../src/pages/api-reference/connect/index.js" /* webpackChunkName: "component---src-pages-api-reference-connect-index-js" */),
  "component---src-pages-api-reference-connect-more-js": () => import("./../../../src/pages/api-reference/connect/more.js" /* webpackChunkName: "component---src-pages-api-reference-connect-more-js" */),
  "component---src-pages-api-reference-js": () => import("./../../../src/pages/api-reference.js" /* webpackChunkName: "component---src-pages-api-reference-js" */),
  "component---src-pages-dashboards-gavle-js": () => import("./../../../src/pages/dashboards/gavle.js" /* webpackChunkName: "component---src-pages-dashboards-gavle-js" */),
  "component---src-pages-dashboards-telge-js": () => import("./../../../src/pages/dashboards/telge.js" /* webpackChunkName: "component---src-pages-dashboards-telge-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-environment-docs-js": () => import("./../../../src/pages/environment/docs.js" /* webpackChunkName: "component---src-pages-environment-docs-js" */),
  "component---src-pages-getting-started-index-js": () => import("./../../../src/pages/getting-started/index.js" /* webpackChunkName: "component---src-pages-getting-started-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lemon-docs-js": () => import("./../../../src/pages/lemon/docs.js" /* webpackChunkName: "component---src-pages-lemon-docs-js" */),
  "component---src-pages-more-js": () => import("./../../../src/pages/more.js" /* webpackChunkName: "component---src-pages-more-js" */),
  "component---src-pages-reitan-docs-js": () => import("./../../../src/pages/reitan/docs.js" /* webpackChunkName: "component---src-pages-reitan-docs-js" */),
  "component---src-pages-v-2-docs-js": () => import("./../../../src/pages/v2/docs.js" /* webpackChunkName: "component---src-pages-v-2-docs-js" */),
  "component---src-pages-webhook-js": () => import("./../../../src/pages/webhook.js" /* webpackChunkName: "component---src-pages-webhook-js" */)
}

